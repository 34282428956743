function isiOS () {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

const pdfFallbackIframe = document.getElementById('pdf-fallback');
const pdfIframe = document.getElementById('pdf-iframe');

if (pdfFallbackIframe && !isiOS()) {
  pdfFallbackIframe.remove();
} else if( pdfIframe ) {
  pdfIframe.remove();
}
